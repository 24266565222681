
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as _91id_935WRrYTZJqdMeta } from "/vercel/path1/pages/bookings/[id].vue?macro=true";
import { default as createXZ3PHm2NNgMeta } from "/vercel/path1/pages/bookings/create.vue?macro=true";
import { default as indexah6XI90FqFMeta } from "/vercel/path1/pages/bookings/index.vue?macro=true";
import { default as confirm_45passwordSJRC9U6BInMeta } from "/vercel/path1/pages/confirm-password.vue?macro=true";
import { default as _91id_93m2Mngspn1aMeta } from "/vercel/path1/pages/customers/[id].vue?macro=true";
import { default as createkzQJUBx0oxMeta } from "/vercel/path1/pages/customers/create.vue?macro=true";
import { default as indexwZqqNYl6t4Meta } from "/vercel/path1/pages/customers/index.vue?macro=true";
import { default as graphql_45explorertugLKAo0W0Meta } from "/vercel/path1/pages/developer/graphql-explorer.vue?macro=true";
import { default as _91id_932zISmH8sIuMeta } from "/vercel/path1/pages/discounts/[id].vue?macro=true";
import { default as create3A4vAJPfFCMeta } from "/vercel/path1/pages/discounts/create.vue?macro=true";
import { default as indexhSo8llzGUBMeta } from "/vercel/path1/pages/discounts/index.vue?macro=true";
import { default as indexzU4zEycnIaMeta } from "/vercel/path1/pages/error/index.vue?macro=true";
import { default as indexzhmolsBfabMeta } from "/vercel/path1/pages/index.vue?macro=true";
import { default as loginlsVM2qGgsfMeta } from "/vercel/path1/pages/login.vue?macro=true";
import { default as authjT8voUsrwuMeta } from "/vercel/path1/pages/oauth/auth.vue?macro=true";
import { default as indexSNtgXOBMAbMeta } from "/vercel/path1/pages/organizations/index.vue?macro=true";
import { default as userswhT7lrZKVTMeta } from "/vercel/path1/pages/organizations/users.vue?macro=true";
import { default as _91id_932suuDHbdoEMeta } from "/vercel/path1/pages/products/[id].vue?macro=true";
import { default as createwEX00bMhh5Meta } from "/vercel/path1/pages/products/create.vue?macro=true";
import { default as indexrc42zYLeoHMeta } from "/vercel/path1/pages/products/index.vue?macro=true";
import { default as register3VIXDPK4aGMeta } from "/vercel/path1/pages/register.vue?macro=true";
import { default as reset_45passwordRY4Jz2aDdWMeta } from "/vercel/path1/pages/reset-password.vue?macro=true";
import { default as indexRDl0kZWQMpMeta } from "/vercel/path1/pages/settings/index.vue?macro=true";
import { default as indexC64oCvN5ATMeta } from "/vercel/path1/pages/settings/payments/index.vue?macro=true";
import { default as indexWv1A9vmlQeMeta } from "/vercel/path1/pages/settings/tags/index.vue?macro=true";
export default [
  {
    name: "bookings-id",
    path: "/bookings/:id()",
    component: () => import("/vercel/path1/pages/bookings/[id].vue")
  },
  {
    name: "bookings-create",
    path: "/bookings/create",
    component: () => import("/vercel/path1/pages/bookings/create.vue")
  },
  {
    name: "bookings",
    path: "/bookings",
    component: () => import("/vercel/path1/pages/bookings/index.vue")
  },
  {
    name: "confirm-password",
    path: "/confirm-password",
    meta: confirm_45passwordSJRC9U6BInMeta || {},
    component: () => import("/vercel/path1/pages/confirm-password.vue")
  },
  {
    name: "customers-id",
    path: "/customers/:id()",
    component: () => import("/vercel/path1/pages/customers/[id].vue")
  },
  {
    name: "customers-create",
    path: "/customers/create",
    component: () => import("/vercel/path1/pages/customers/create.vue")
  },
  {
    name: "customers",
    path: "/customers",
    component: () => import("/vercel/path1/pages/customers/index.vue")
  },
  {
    name: "developer-graphql-explorer",
    path: "/developer/graphql-explorer",
    component: () => import("/vercel/path1/pages/developer/graphql-explorer.vue")
  },
  {
    name: "discounts-id",
    path: "/discounts/:id()",
    component: () => import("/vercel/path1/pages/discounts/[id].vue")
  },
  {
    name: "discounts-create",
    path: "/discounts/create",
    component: () => import("/vercel/path1/pages/discounts/create.vue")
  },
  {
    name: "discounts",
    path: "/discounts",
    component: () => import("/vercel/path1/pages/discounts/index.vue")
  },
  {
    name: "error",
    path: "/error",
    component: () => import("/vercel/path1/pages/error/index.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/vercel/path1/pages/index.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: loginlsVM2qGgsfMeta || {},
    component: () => import("/vercel/path1/pages/login.vue")
  },
  {
    name: "oauth-auth",
    path: "/oauth/auth",
    component: () => import("/vercel/path1/pages/oauth/auth.vue")
  },
  {
    name: "organizations",
    path: "/organizations",
    component: () => import("/vercel/path1/pages/organizations/index.vue")
  },
  {
    name: "organizations-users",
    path: "/organizations/users",
    component: () => import("/vercel/path1/pages/organizations/users.vue")
  },
  {
    name: "products-id",
    path: "/products/:id()",
    component: () => import("/vercel/path1/pages/products/[id].vue")
  },
  {
    name: "products-create",
    path: "/products/create",
    component: () => import("/vercel/path1/pages/products/create.vue")
  },
  {
    name: "products",
    path: "/products",
    component: () => import("/vercel/path1/pages/products/index.vue")
  },
  {
    name: "register",
    path: "/register",
    meta: register3VIXDPK4aGMeta || {},
    component: () => import("/vercel/path1/pages/register.vue")
  },
  {
    name: "reset-password",
    path: "/reset-password",
    meta: reset_45passwordRY4Jz2aDdWMeta || {},
    component: () => import("/vercel/path1/pages/reset-password.vue")
  },
  {
    name: "settings",
    path: "/settings",
    component: () => import("/vercel/path1/pages/settings/index.vue")
  },
  {
    name: "settings-payments",
    path: "/settings/payments",
    component: () => import("/vercel/path1/pages/settings/payments/index.vue")
  },
  {
    name: "settings-tags",
    path: "/settings/tags",
    component: () => import("/vercel/path1/pages/settings/tags/index.vue")
  }
]